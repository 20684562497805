@font-face {
  font-family: "ocr-a-std";
  src: url("ocr.woff2") format("woff2"), url("ocr.woff") format("woff"),
    url("ocr.ttf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-ocr-a-std {
  font-family: "ocr-a-std", monospace;
}

*:focus {
  outline: none;
}

body {
  background-color: #000;
  padding: 40px;
  box-sizing: border-box;
}

@keyframes crt-flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.terminal {
  margin: 0 auto;
  letter-spacing: 0px;

  height: 80vh;
  background-color: rgba(18, 62, 17, 0.2);
  background: repeating-linear-gradient(
    0deg,
    rgba(18, 62, 17, 0.25),
    rgba(18, 62, 17, 0.25) 2px,
    #000 2px,
    #000 4px
  );
  color: #00a41b;
  font-size: 8px;
  line-height: 16px;
  border: 1px solid #123e11;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  animation: crt-flashing 0.5s linear 3;
}
@media screen and (min-width: 120px) {
  .terminal {
    font-size: 4;
  }
}
@media screen and (min-width: 500px) {
  .terminal {
    font-size: 10;
  }
}
@media screen and (min-width: 600px) {
  .terminal {
    font-size: 11;
  }
}

@media screen and (min-width: 768px) {
  .terminal {
    font-size: 12;
  }
}

@media screen and (min-width: 992px) {
  .terminal {
    font-size: 13;
  }
}

@media screen and (min-width: 1280px) {
  .terminal {
    font-size: 14;
  }
}

@media screen and (min-width: 1920px) {
  .terminal {
    font-size: 16;
  }
}
@media screen and (min-width: 3840px) {
  .terminal {
    font-size: 17;
  }
}

.terminal .terminal-content {
  height: calc(100% - 28px);
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
  mix-blend-mode: screen;
}

.terminal .terminal-content .terminal-content-line {
  display: block;
  text-shadow: 0 0 12px rgba(12, 250, 0, 0.8);
  white-space: pre-wrap; /* updated to handle line wrapping */
  margin-bottom: 0px;
}

.terminal .terminal-content .terminal-content-line.blue {
  color: #388fff;
  text-shadow: 0 0 12px rgba(56, 143, 255, 0.8);
}

.terminal
  .terminal-content
  .terminal-content-line
  .terminal-content-line-letter {
  white-space: pre;
  display: inline-block;
}

.terminal .terminal-input {
  letter-spacing: -1px;
  height: 28px;
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #00a41b;
  border: 0;
  border-top: 1px solid #123e11;
  box-sizing: border-box;
  resize: none;
}
