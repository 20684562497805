@font-face {
  font-family: ocr-a-std;
  src: url("ocr.8c584b78.woff2") format("woff2"), url("ocr.9180868b.woff") format("woff"), url("ocr.52371508.ttf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-ocr-a-std {
  font-family: ocr-a-std, monospace;
}

:focus {
  outline: none;
}

body {
  box-sizing: border-box;
  background-color: #000;
  padding: 40px;
}

@keyframes crt-flashing {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .3;
  }

  100% {
    opacity: 1;
  }
}

.terminal {
  letter-spacing: 0;
  color: #00a41b;
  box-sizing: border-box;
  background: repeating-linear-gradient(0deg, #123e1140, #123e1140 2px, #000 2px 4px);
  border: 1px solid #123e11;
  border-radius: 4px;
  height: 80vh;
  margin: 0 auto;
  font-size: 8px;
  line-height: 16px;
  animation: .5s linear 3 crt-flashing;
  overflow: hidden;
}

@media screen and (width >= 120px) {
  .terminal {
    font-size: 4px;
  }
}

@media screen and (width >= 500px) {
  .terminal {
    font-size: 10px;
  }
}

@media screen and (width >= 600px) {
  .terminal {
    font-size: 11px;
  }
}

@media screen and (width >= 768px) {
  .terminal {
    font-size: 12px;
  }
}

@media screen and (width >= 992px) {
  .terminal {
    font-size: 13px;
  }
}

@media screen and (width >= 1280px) {
  .terminal {
    font-size: 14px;
  }
}

@media screen and (width >= 1920px) {
  .terminal {
    font-size: 16px;
  }
}

@media screen and (width >= 3840px) {
  .terminal {
    font-size: 17px;
  }
}

.terminal .terminal-content {
  box-sizing: border-box;
  mix-blend-mode: screen;
  height: calc(100% - 28px);
  padding: 16px;
  overflow: auto;
}

.terminal .terminal-content .terminal-content-line {
  text-shadow: 0 0 12px #0cfa00cc;
  white-space: pre-wrap;
  margin-bottom: 0;
  display: block;
}

.terminal .terminal-content .terminal-content-line.blue {
  color: #388fff;
  text-shadow: 0 0 12px #388fffcc;
}

.terminal .terminal-content .terminal-content-line .terminal-content-line-letter {
  white-space: pre;
  display: inline-block;
}

.terminal .terminal-input {
  letter-spacing: -1px;
  color: #00a41b;
  box-sizing: border-box;
  resize: none;
  background-color: #000;
  border: 0;
  border-top: 1px solid #123e11;
  width: 100%;
  height: 28px;
  padding: 10px;
}

/*# sourceMappingURL=index.ac015e3f.css.map */
